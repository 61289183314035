body {
  background-color: #f8f9fa;
}

.navbar {
  margin-bottom: 20px;
}

.container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
}
